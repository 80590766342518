@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(https://images.unsplash.com/photo-1622547748225-3fc4abd2cca0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=100);
}

#__next {
  width: 100vw;
  min-height: 100vh;
  -webkit-backdrop-filter: var(--blur);
          backdrop-filter: var(--blur);
  font-family: var(--rlm-font);
}


:root {
  --theme-mode: light;
  --rlm-font: "Rubik",sans-serif;
  --blur: blur(24px);
  --transition-fast: 0.4s ease;
  --transition: all 0.25s ease;
  --transition-2x: all 0.5s ease;
  --rlm-border-radius-4: 4px;
  --rlm-border-radius-6: 6px;
  --rlm-border-radius-9: 9px;
  --rlm-border-radius-12: 12px;
  --rlm-border-radius-16: 16px;
  --rlm-box-shadow-1: 0px 0px 4px rgba(0,0,0,0.06);
  --rlm-box-shadow-2: 0px 0px 9px rgba(0,0,0,0.12);
  --rlm-box-shadow-3: 0px 0px 9px rgba(0,0,0,0.18);
  --rlm-box-shadow-lifted: 0px 0px 9px rgba(0,0,0,0.24);
  --rlm-home-button-rgba: 204,204,204,0.5;
  --rlm-dock-rgba: 255,255,255,0.65;
  --rlm-base-rgba: 196,195,191;
  --rlm-accent-rgba: 78,158,253;
  --rlm-input-rgba: 255,255,255;
  --rlm-border-rgba: 230,230,230;
  --rlm-window-rgba: 255,255,255;
  --rlm-window-bg-rgba: 255,255,255,0.9;
  --rlm-card-rgba: 255,255,255;
  --rlm-text-rgba: 51,51,51;
  --rlm-icon-rgba: 51,51,51,0.7;
  --rlm-mouse-rgba: 78,158,253;
  --rlm-brand-rgba: 240,135,53;
  --rlm-intent-alert-rgba: 255,98,64;
  --rlm-intent-caution-rgba: 255,188,50;
  --rlm-intent-success-rgba: 15,195,131;
  --rlm-overlay-hover-rgba: 0,0,0,0.04;
  --rlm-overlay-active-rgba: 0,0,0,0.06;
  --rlm-home-button-color: rgba(204,204,204,0.5);
  --rlm-dock-color: rgba(255,255,255,0.65);
  --rlm-base-color: #C4C3BF;
  --rlm-accent-color: #4E9EFD;
  --rlm-input-color: #FFFFFF;
  --rlm-border-color: #e6e6e6;
  --rlm-window-color: #FFFFFF;
  --rlm-window-bg-color: rgba(255,255,255,0.9);
  --rlm-card-color: #fff;
  --rlm-text-color: #333333;
  --rlm-icon-color: rgba(51,51,51,0.7);
  --rlm-mouse-color: #4E9EFD;
  --rlm-brand-color: #F08735;
  --rlm-intent-alert-color: #ff6240;
  --rlm-intent-caution-color: #ffbc32;
  --rlm-intent-success-color: #0fc383;
  --rlm-overlay-hover-color: rgba(0,0,0,0.04);
  --rlm-overlay-active-color: rgba(0,0,0,0.06);
}

a {
  color: rgba(var(--rlm-accent-rgba));
}

a:visted {
  color: rgba(var(--rlm-accent-rgba));
}

